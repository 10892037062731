<template>
    <div>
      <!-- <div>
        <p>{{ this.$route.params.id }}</p>
      </div> -->
      <div class="row justify-content-center justify-content-around">
        <div class="card col-12 col-md-12 p-1">
          <div>
            <b-button variant="gradient-secondary" class="mr-1"
              @click="$router.push({ name: 'annualcheck' })">กลับ</b-button>
            <!-- <b-button class="ml-1" v-b-modal.modal-1 variant="outline-primary"> ลงชื่อกรรมกาตรวจนับ </b-button> -->
            <!-- <b-button class="ml-1" v-b-modal.modal-2 variant="outline-primary">
              ผู้ให้ถ้อยคำสอบข้อเท็จจริง
            </b-button> -->
  
            <!-- basic modal -->
            <b-modal id="modal-1" title="ลงชื่อรายงานผลการตรวจสอบทรัพย์สิน" ok-only ok-title="ตกลง" size="md"
              @ok="inspectorSignName" no-close-on-backdrop>
              <b-card-text>
                <b-form-group label-cols="3" label-cols-lg="2" label="ประธานกรรมการ:">
                  <v-select :options="alluser" label="firstname" v-model="inspector1" placeholder="ชื่อ-สกุล"
                    @input="a_position(inspector1)" />
                </b-form-group>
                <b-form-group label-cols="3" label-cols-lg="2" label="ตำเเหน่ง:">
                  <v-select :options="department_Lists" label="dep_id" placeholder="ตำแหน่ง" v-model="inspector_pos1"
                    disabled />
                </b-form-group>
                <b-form-group label-cols="3" label-cols-lg="2" label="กรรมการ:">
                  <v-select :options="alluser" label="firstname" v-model="inspector2" placeholder="ชื่อ-สกุล"
                    @input="b_position(inspector2)" />
                </b-form-group>
                <b-form-group label-cols="3" label-cols-lg="2" label="ตำเเหน่ง:">
                  <v-select :options="department_Lists" label="dep_id" placeholder="ตำแหน่ง" v-model="inspector_pos2"
                    disabled />
                </b-form-group>
                <b-form-group label-cols="3" label-cols-lg="2" label="กรรมการเเละเลขานุการ:">
                  <v-select :options="alluser" label="firstname" v-model="inspector3" placeholder="ชื่อ-สกุล"
                    @input="c_position(inspector3)" />
                </b-form-group>
                <b-form-group label-cols="3" label-cols-lg="2" label="ตำเเหน่ง:">
                  <v-select :options="department_Lists" label="dep_id" placeholder="ตำแหน่ง" v-model="inspector_pos3"
                    disabled />
                </b-form-group>
              </b-card-text>
            </b-modal>
  
            <!-- <b-modal
              id="modal-2"
              title="ผู้ให้ถ้อยคำสอบข้อเท็จจริง"
              ok-only
              ok-title="ตกลง"
            >
              <b-card-text>
                <b-form-group label-cols="3" label-cols-lg="2" label="ชื่อ:">
                  <v-select
                    :options="alluser"
                    label="firstname"
                    v-model="middle2"
                    placeholder="ชื่อ-สกุล"
                  />
                </b-form-group>
                <b-form-group label-cols="3" label-cols-lg="2" label="ตำเเหน่ง:">
                  <v-select
                    :options="department_Lists"
                    label="dep_id"
                    placeholder="ตำแหน่ง"
                    v-model="department2"
                  />
                </b-form-group>
              </b-card-text>
            </b-modal> -->
  
            <hr />
            <div class="title head">
              <p class="h2" style="color: #558cef">แบบสำรวจทรัพย์สินประจำปี</p>
            </div>
            <!-- search input -->
            <div class="row justify-content-start">
              <div class="h4 ml-1" style="color: #558cef">รหัส :</div>
              <div class="h4">{{ this.$route.params.id.check_code }}</div>
              <div class="ml-1 h4 text-primary" text="primary">ปีงบประมาณ :</div>
              <div class="ml-1 h4">{{ this.$route.params.id.budget_year }}</div>
              <div class="ml-1 h4 text-primary" text="primary">วันที่ :</div>
              <div class="ml-1 h4">{{ this.$route.params.id.start_date_th }}</div>
              <div class="ml-1 h4 text-primary" text="primary">ถึงวันที่ :</div>
              <div class="ml-1 h4">{{ this.$route.params.id.stop_date_th }}</div>
            </div>
  
            <hr />
            <div class="row justify-content-end">
              <b-form-group class="col-12 col-md-6">
                <div class="d-flex align-items-center">
                  <label class="mr-1">ค้นหา</label>
                  <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                  <div>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <b-button variant="outline-primary"><feather-icon icon="ShareIcon" class="mr-50" />Export
                          <feather-icon icon="ChevronDownIcon" class="mr-50" /></b-button>
                      </template>
  
                      <b-dropdown-item @click="exportExcel">
                        <feather-icon icon="FileIcon" class="mr-50" />
                        Excel
                      </b-dropdown-item>
                      <b-dropdown-item @click="exportPDF">
                        <feather-icon icon="FileIcon" class="mr-50" />
                        PDF
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </b-form-group>
            </div>
            <!-- table -->
            <b-overlay :show="show" rounded="sm">
              <vue-good-table :columns="columns" :rows="rows" :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }" :pagination-options="{
    enabled: true,
    perPage: pageLength,
  }" :line-numbers="true">
                <template slot="table-row" slot-scope="props">
                  <!-- Column: Name -->
                  <!-- {{ props.row }} -->
                  <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                    <b-avatar :src="props.row.avatar" class="mx-1" />
                    <span class="text-nowrap">{{ props.row.fullName }}</span>
                  </span>
                  <span v-if="props.column.field === 'ใช้ได้ใช้ไม่ได้'" class="text-nowrap">
                    {{ props.row }}
                    asdasdasd
                    <div v-if="'ใช้ได้ใช้ไม่ได้' == 'ใช้ได้'">
                      ใช้ได้
                    </div>
                    <div v-else>
  
                    </div>
                  </span>
                  <!-- <span v-if="props.column.field === 'ใช้ได้ใช้ไม่ได้'" class="text-nowrap">
                    <div v-if="'ใช้ได้ใช้ไม่ได้' == 'ใช้ไม่ได้'">
                      ใช้ไม่ได้
                    </div>
                    <div v-else>
  
                    </div> -->
                  </span>
  
  
                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
  
                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Showing 1 to </span>
                      <b-form-select v-model="pageLength" :options="['5', '10', '15', '25', '50', '100']" class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))" />
                      <span class="text-nowrap">of {{ total }} entries</span>
                    </div>
                    <div>
                      <b-pagination :value="1" :total-rows="total" :per-page="pageLength" first-number last-number
                        align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                        @input="handlePagechange($event)">
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-overlay>
          </div>
        </div>
        <!-- <div class="card col-12 col-md-2"></div> -->
      </div>
    </div>
  </template>
  
  <script>
  import { BTabs, BTab } from 'bootstrap-vue';
  import { BFormInput, BRow, BCol, BFormGroup } from 'bootstrap-vue';
  import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from 'bootstrap-vue';
  import vSelect from 'vue-select';
  import { BButton } from 'bootstrap-vue';
  import { BFormFile } from 'bootstrap-vue';
  import { BFormSelect } from 'bootstrap-vue';
  import { BFormDatepicker } from 'bootstrap-vue';
  import { VueGoodTable } from 'vue-good-table';
  import axios from 'axios';
  import API from '@/views/connectDB/condb.js';
  import { saveExcel } from '@progress/kendo-vue-excel-export';
  import _ from 'lodash';
  import pdfMake from "pdfmake";
  import pdfFonts from "../font/custom-fonts";
  import dayjs from "dayjs";
  import buddhistEra from "dayjs/plugin/buddhistEra";
  dayjs.extend(buddhistEra);
  import isBetween from "dayjs/plugin/isBetween";
  dayjs.extend(isBetween);
  export default {
    components: {
      VueGoodTable,
      BFormDatepicker,
      BFormSelect,
      BFormFile,
      BRow,
      BCol,
      BButton,
      BTable,
      BCardBody,
      vSelect,
      BFormRadio,
      BFormCheckbox,
      BFormRadioGroup,
      BFormInput,
      BFormGroup,
      BRow,
      BCol,
      BTabs,
      BTab,
    },
    data() {
      return {
        pageLength: 10,
        currentPage: 1,
        total: '',
        show: false,
        selected: null,
        options: [
          { value: null, text: 'เลือกประเภทหลักฐาน' },
          { value: 'a', text: 'ใบส่งของ/ใบกำกับาษี' },
  
          // { value: { C: '3PO' }, text: 'This is an option with object value' },
          // { value: 'd', text: 'This one is disabled', disabled: true },
        ],
        columns: [
          {
            label: "วันที่-เดือน-ปี",
            field: "วันที่รับเข้า",
          },
          {
            label: "เลขทะเบียน",
            field: "เลขทะเบียน",
          },
          {
            label: "รหัสทรัพย์สิน",
            field: "หมายเลขทรัพย์สิน",
          },
  
          {
            label: "ชื่อทรัพย์สิน",
            field: "ชื่อทรัพย์สิน",
          },
          {
            label: "รายการ รายละเอียด",
            field: "รายละเอียด",
          },
          {
            label: "หน่วยนับ",
            field: "หน่วยนับ",
          },
          {
            label: "ราคาต่อหน่วย",
            field: "ราคาต่อหน่วย",
            formatFn: this.formatFn,
          },
          {
            label: "จำนวน",
            field: "จำนวน",
          },
          {
            label: "มูลค่ารวม",
            field: "มูลค่ารวม",
            formatFn: this.formatFn,
          },
          {
            label: "อายุการใช้งาน",
            field: "อายุการใช้งาน",
            formatFn: this.formatFn,
          },
          {
            label: "อัตราค่าเสื่อมราคา",
            field: "อัตราค่าเสื่อมราคา",
            formatFn: this.formatFn,
          },
          {
            label: "ค่าเสื่อมประจำปี",
            field: "อัตราค่าเสื่อมราคาต่อปี",
            formatFn: this.formatFn,
          },
          {
            label: "ค่าเสื่อมราคาสะสม",
            field: "ค่าเสื่อมราคาสะสม",
            formatFn: this.formatFn,
          },
          {
            label: "มูลค่าสุทธิ",
            field: "มูลค่าสุทธิ",
            formatFn: this.formatFn,
          },
          {
            label: "ใช้ได้",
            field: "ใช้ได้",
          },
          {
            label: "ใช้ไม่ได้",
            field: "ใช้ไม่ได้",
          },
          {
            label: "ซ่อม",
            field: "ซ่อม",
          },
          {
            label: "จำหน่าย",
            field: "จำหน่าย",
          },
          {
            label: "สถานที่เก่า/ผู้รับผิดชอบ",
            field: "สถานที่เก่า",
          },
          {
            label: "สถานที่ใหม่/ผู้รับผิดชอบ",
            field: "สถานที่ใหม่",
          },
        ],
        month: [
          "",
          "มกราคม",
          "กุมภาพันธ์ ",
          "มีนาคม ",
          "เมษายน ",
          "พฤษภาคม ",
          "มิถุนายน",
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม",
        ],
        rows: [],
        searchTerm: '',
        alluser: [],
  
        inspector1: '',
        inspector_pos1: '',
        inspector2: '',
        inspector_pos2: '',
        inspector3: '',
        inspector_pos3: '',
  
        department_Lists: [],
        department1: '',
        department2: '',
      };
    },
    mounted() {
      this.get_data_table();
      this.get_data_table_user();
      this.departmentLists();
    },
    created() {
      console.log(this.$route.params.id);
      _.isEmpty(this.$route.params.id.check_code) ? this.$router.push({ path: '/annual-check' }) : true;
    },
    methods: {
      async exportPDF() {
        const { access_token } = await this.access_token();
        const url = `${API}readEquipmentCheckList?check_code=${this.$route.params.id.check_code}&_page=${this.currentPage}&_limit=${this.pageLength}&_sort=-1`;
  
        // console.log(url)
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        console.log(res.data.message.data);
        const res1 = res.data.message.data
        const res2 = []
        const url2 = await res1.map((res) => {
          // console.log(res)
          return `${API}propertyRegistration?e_number=${res.e_number}&lifetime=${res.lifetime}&refkey=${res.refkey}`;
        });
        for (let i in url2) {
          const data2 = await axios.get(url2[i], head);
          console.log(data2);
  
          res2.push(data2.data.message.data);
        }
        console.log(this.$route.params.id)
  
        var dateSelect = "";
        console.log("3333");
        var _checkdate = dayjs(this.$route.params.id.start_date).isBetween(
          this.$route.params.id.start_date.split("-")[0] + "-10-01",
          this.$route.params.id.start_date.split("-")[0] + "-12-31",
          undefined,
          "[]"
        );
        console.log(_checkdate);
        const chk = dayjs(this.$route.params.id.stop_date).isBetween(
          this.$route.params.id.stop_date.split("-")[0] + "-10-01",
          this.$route.params.id.stop_date.split("-")[0] + "-12-31",
          undefined,
          "[]"
        );
        console.log(chk);
        if (_checkdate == true && chk == true) {
          dateSelect = `${Number(this.$route.params.id.stop_date.split("-")[0]) + 1}`;
        } else if (_checkdate == true && chk == false) {
          dateSelect = `${Number(this.$route.params.id.stop_date.split("-")[0])}`;
        } else {
          dateSelect = `${Number(this.$route.params.id.stop_date.split("-")[0])}`;
        }
        // console.log(dateSelect)
        const ff = []
        let g_names = "";
        let lastData2 = []
        let lastData = []
        const dataFilter = res2.map((res, index) => {
          //  const [response] = res.filter((el, index2) => dateSelect.toString() == el.admitdate.substring(0, 4).toString() && index2 !== 0);
          res.forEach((element, index2) => {
            const cc = element.admitdate.substring(0, 4);
            //  console.log(cc)
            //  console.log(dateSelect.toString())
            if (cc == dateSelect.toString() && index2 !== 0) {
              ff.push(element);
            }
          });
          // console.log(response)
          // return response;
        });
        console.log(res1[0])
        ff.map(async (res, index) => {
          console.log(res)
          res1[index]["ค่าเสื่อมราคาประจำปี"] = ''
          //   console.log( res["ค่าเสื่อมราคาประจำปี"])
  
          // const cc= {
          //   'ค่าเสื่อมราคาประจำปี':await res["ค่าเสื่อมราคาประจำปี"]
          // }
  
          res1[index]["ค่าเสื่อมราคาสะสม"] = res["ค่าเสื่อมราคาสะสม"],
            res1[index]["มูลค่าสุทธิ"] = res["มูลค่าสุทธิ"],
            res1[index]["อัตราค่าเสื่อมราคาต่อปี"] = res["อัตราค่าเสื่อมราคาต่อปี"],
            res1[index]["อัตราค่าเสื่อมราคาต่อวัน"] = res["อัตราค่าเสื่อมราคาต่อวัน"]
          res1[index]["ค่าเสื่อมราคาประจำปี"] = res["ค่าเสื่อมราคาประจำปี"]
          console.log(res1)
          // console.log(res1[0].check_code)
  
          await (res1[index]["admitdate"] = `${parseInt(res.admitdate.split("-")[2])} ${this.month[parseInt(res.admitdate.split("-")[1])]
            } ${Number(res.admitdate.split("-")[0]) + 543}`)
  
          // await (res1[index]["ค่าเสื่อมราคาประจำปี"] = ),
          //  res1[index](cc)
          // await (res1[index]["ค่าเสื่อมราคาสะสม"] = res["ค่าเสื่อมราคาสะสม"]),
          // await (res1[index]["มูลค่าสุทธิ"] = res["มูลค่าสุทธิ"]),
          // await (res1[index]["อัตราค่าเสื่อมราคาต่อปี"] = res["อัตราค่าเสื่อมราคาต่อปี"]),
          // await (res1[index]["อัตราค่าเสื่อมราคาต่อวัน"] = res["อัตราค่าเสื่อมราคาต่อวัน"])
  
        });
  
  
        // console.log(res.data.message.data)
        // console.log(ff)
        console.log(res1)
  
        res1.map((res, index) => {
  
          //console.log(res.g_name, index);
          //console.log("dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd");
          const raw = {
            g_name: "",
            e_gid: "",
            ค่าเสื่อมประจำปี: "",
            ค่าเสื่อมราคาสะสม: "",
            จำนวน: "",
            จำหน่าย: "",
            ซ่อม: "",
            มูลค่ารวม: "",
            มูลค่าสุทธิ: "",
            ราคาต่อหน่วย: "",
            ราคาต่อหน่วยรวมvat: "",
            รายละเอียด: "",
            วันที่รับเข้า: "",
            สถานที่เก่า: "",
            สถานที่ใหม่: "",
            หน่วยนับ: "",
            หมายเลขทรัพย์สิน: "",
            อัตราค่าเสื่อมราคา: "",
            อัตราค่าเสื่อมราคาต่อปี: "",
            อัตราค่าเสื่อมราคาต่อวัน: "",
            อายุการใช้งาน: "",
            เลขทะเบียน: "",
            ใช้ได้ใช้ไม่ได้: "",
          };
          if (res.g_name === "" || res.g_name !== g_names) {
            // header
            raw["ชื่อทรัพย์สิน"] = res.g_name;
            lastData.push(raw);
            g_names = res.g_name;
            lastData2.push({ [res.g_name]: [] });
            //console.log(lastData2);
          }
          res["มูลค่ารวม"] = res["มูลค่ารวม"];
  
          res.keys = index;
          (res["ซ่อม"] = res["ซ่อม"] != "" && res["ซ่อม"] != undefined ? "ซ่อม" : ""),
            (res["จำหน่าย"] = res["จำหน่าย2"] != "" && res["จำหน่าย2"] != undefined ? "จำหน่าย" : "")
          console.log(res.ค่าเสื่อมราคาประจำปี)
  
  
          res["วันที่รับเข้า"] = res.admitdate
          res["เลขทะเบียน"] = res.equipment_code
          res["หมายเลขทรัพย์สิน"] = res.e_number
          res["ชื่อทรัพย์สิน"] = res.information
          res["รายละเอียด"] = res.e_name
          res["หน่วยนับ"] = res.unittype
          res["ราคาต่อหน่วย"] = res.unitprice
          res["จำนวน"] = res.amount
          res["มูลค่ารวม"] = res.unitprice
          res["อายุการใช้งาน"] = res.lifetime
          res["อัตราค่าเสื่อมราคา"] = res.อัตราค่าเสื่อมราคาต่อปี
          res["อัตราค่าเสื่อมราคาต่อปี"] = res.อัตราค่าเสื่อมราคาต่อปี
          res["ค่าเสื่อมราคาสะสม"] = res.ค่าเสื่อมราคาสะสม
          res["มูลค่าสุทธิ"] = res.มูลค่าสุทธิ
          res["ใช้ได้ใช้ไม่ได้"] = res.performance
          res["ซ่อม"] = res.ซ่อม
          res["จำหน่าย"] = res.จำหน่าย
          res["สถานที่เก่า"] = res.place
          res["สถานที่ใหม่"] = res.remark
  
  
          lastData.push(res);
  
  
  
          console.log(lastData);
          // console.log(lastData2);
          // console.log(res);
          for (let i in lastData2) {
            //console.log(Object.keys(lastData2[i]));
            //console.log(res);
  
            //console.log(lastData2[i][Object.keys(lastData2[i])]);
            if (res.g_name == Object.keys(lastData2[i])) {
              // console.log(lastData2);
              lastData2[i][Object.keys(lastData2[i])].push(res);
            }
          }
        });
  
        console.log(lastData)
  
  
        console.log(lastData2);
  
        var x1 = 0;
        var x2 = 0;
        var x3 = 0;
        var x4 = 0;
  
        for (let i in lastData2) {
          console.log(lastData2[i][Object.keys(lastData2[i])]);
          for (let r in lastData2[i][Object.keys(lastData2[i])]) {
            // console.log(lastData2[i][Object.keys(lastData2[i])][r].ค่าเสื่อมประจำปี)
            x1 += Number(lastData2[i][Object.keys(lastData2[i])][r].ค่าเสื่อมประจำปี);
            x2 += Number(lastData2[i][Object.keys(lastData2[i])][r].ค่าเสื่อมราคาสะสม);
            x3 += Number(lastData2[i][Object.keys(lastData2[i])][r].มูลค่าสุทธิ);
            x4 += Number(lastData2[i][Object.keys(lastData2[i])][r].ราคาต่อหน่วย);
          }
          lastData2[i][Object.keys(lastData2[i])].push({
            ค่าเสื่อมประจำปี: x1,
            ค่าเสื่อมราคาสะสม: x2,
            มูลค่าสุทธิ: x3,
            มูลค่ารวม: x4,
            รายละเอียด: "รวมเป็นเงินทั้งสิ้น",
          });
          // lastData2[i][Object.keys(lastData2[i])].push(res);
          x1 = 0;
          x2 = 0;
          x3 = 0;
          x4 = 0;
        }
  
        // console.log(xxx);
        console.log(lastData2);
        let ooo = 0;
        var namepp = "";
        // this.rows = lastData;
        // this.show = false;
  
        const res_last = lastData2.map((res, index) => {
          return {
            [Object.keys(res)[0]]: res[Object.keys(res)].map((res2, index, arr) => {
              console.log(res2.อัตราค่าเสื่อมราคาต่อปี);
              console.log(index);
              console.log(arr.length);
              namepp = res2.g_name;
  
              // console.log(res2.เลขทะเบียน);
              if (res2.เลขทะเบียน != undefined) {
                ooo = ooo + 1;
              } else {
                ooo = 0;
              }
              let gen_key = "";
  
              const data_return = [
                { text: `${res2.เลขทะเบียน ? ooo : ""}`, style: "text_center" },
                { text: res2.วันที่รับเข้า, style: "text_left" },
                { text: res2.เลขทะเบียน, style: "text_left" },
                { text: res2.หมายเลขทรัพย์สิน, style: "text_left" },
                {
                  text: `${res2.รายละเอียด === "รวมเป็นเงินทั้งสิ้น" ? "รวมเป็นเงินทั้งสิ้น" : res2.รายละเอียด}`,
                  style: "text_left",
                },
                { text: res2.หน่วยนับ, style: "text_center" },
                {
                  text:
                    Number(res2.ราคาต่อหน่วย)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") === "NaN"
                      ? " "
                      : Number(res2.ราคาต่อหน่วย)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                  style: "text_right",
                },
                { text: res2.จำนวน, style: "text_right" },
                {
                  text:
                    Number(res2.มูลค่ารวม)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") == 0.0
                      ? " "
                      : Number(res2.มูลค่ารวม)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
                  style: "text_right",
                },
                {
                  text: res2.อายุการใช้งาน,
                  style: "text_right",
                },
                {
                  text:
                    res2
                      ? Number(res2.อัตราค่าเสื่อมราคาต่อปี
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") == NaN
                        ? " "
                        : Number(res2.อัตราค่าเสื่อมราคาต่อปี)
                          .toFixed(2)
                          .toString().replace(NaN, '')
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      : index === arr.length - 1
                        ? Number(res2.อัตราค่าเสื่อมราคาต่อปี
                        )
                          .toFixed(2)
                          .toString().replace(NaN, '')
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        : "0.00",
                  style: "text_right",
                },
                {
                  text:
                    res2
                      ? Number(res2.ค่าเสื่อมราคาสะสม
                      )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") == 0.0
                        ? " "
                        : Number(res2.ค่าเสื่อมราคาสะสม
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      : index === arr.length - 1
                        ? Number(res2.ค่าเสื่อมราคาสะสม
                        )
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        : "0.00",
                  style: "text_right",
                },
                {
                  text:
                    res2
                      ? Number(res2.ค่าเสื่อมราคาสะสม)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") == 0.0
                        ? " "
                        : Number(res2.ค่าเสื่อมราคาสะสม)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      : index === arr.length - 1
                        ? Number(res2.ค่าเสื่อมราคาสะสม)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        : "0.00",
                  style: "text_right",
                },
                {
                  text:
                    res2
                      ? Number(res2.มูลค่าสุทธิ)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") == 0.0
                        ? " "
                        : Number(res2.มูลค่าสุทธิ)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      : index === arr.length - 1
                        ? Number(res2.มูลค่าสุทธิ)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                        : "0.00",
                  style: "text_right",
                },
                { text: `${res2.ใช้ได้ใช้ไม่ได้ === "ใช้ได้" ? "ใช้ได้" : ""}`, style: "text_left" },
                { text: `${res2.ใช้ได้ใช้ไม่ได้ === "สูญหาย" || res2.ใช้ได้ใช้ไม่ได้ == "ใช้ไม่ได้" ? "สูญหาย" : ""}`, style: "text_left" },
  
  
                {
                  text: res2.ซ่อม,
                  style: "text_left",
                },
                {
                  text: res2.จำหน่าย,
                  style: "text_left",
                },
                { text: res2.สถานที่เก่า, style: "text_left" },
                { text: res2.สถานที่ใหม่, style: "text_left" },
              ];
              return data_return;
            }),
          };
        });
  
        console.log("---------------------------------------------------");
        console.log(res_last);
        console.log("---------------------------------------------------");
  
        console.log("---------------------------------------------------");
  
        const contentData = res_last.map((res, idx, arr) => {
          console.log(idx);
          console.log(arr.length);
          console.log(Object.keys(arr[idx])[0]);
          console.log("landscape");
          return [
            // idx !== arr.length - 1
            //   ? { text: `${Object.keys(arr[idx])[0]}`, pageOrientation: 'landscape' }
            //   : arr.length - 1 === 0
            //   ? { text: `${Object.keys(arr[idx])[0]}`, pageOrientation: 'landscape' }
            //   : {},
            idx == 0 ? { text: `${Object.keys(arr[idx])[0]}`, pageOrientation: "landscape" } : {},
            {
              style: "tableHeader",
              table: {
                headerRows: 3,
                keepWithHeaderRows: 2,
                widths: [
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "*",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                  "auto",
                ],
                body: [
                  [
                    {
                      text: "ลำ ดับ",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "วัน-เดือน-ปี",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "เลขทะเบียน",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "รหัสทรัพย์สิน",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "รายการ",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "หน่วย นับ",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "ราคา/หน่วย",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "จำ นวน",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "มูลค่ารวม",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "อายุ ใช้งาน",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "อัตตรา ค่าเสื่อม",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "ค่าเสื่อม ประจำปี",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "ค่าเสื่อม ราคาสะสม",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "มูลค่า สุทธิ",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
  
                    {
                      text: "สภาพ",
                      style: "tableHeader",
                      alignment: "center",
                      colSpan: 4,
                    },
                    {},
                    {},
                    {},
  
                    {
                      text: "สถานที่/ผู้รับผิดชอบ (เดิม)",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                    {
                      text: "สถานที่/ผู้รับผิดชอบ (ใหม่)",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 3,
                    },
                  ],
                  [
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "ใช้ได้",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "ใช้ ไม่ได้",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "ซ่อม",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "จำหน่าย",
                      style: "tableHeader",
                      alignment: "center",
                      rowSpan: 2,
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                  ],
                  [
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                    },
                  ],
                  ...res[Object.keys(res)[0]],
                ],
              },
            },
            idx !== arr.length - 1
              ? {
                text: `${Object.keys(arr[idx + 1])[0]}`,
                pageOrientation: "landscape",
                pageBreak: "before",
              }
              : {},
          ];
        });
        this.contentData = contentData;
  
        pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
        pdfMake.fonts = {
          // download default Roboto font from cdnjs.com
          Roboto: {
            normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
            bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
            italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
            bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
          },
          // Kanit Font
          Kanit: {
            // 3. set Kanit font
            normal: "Sarabun-Regular.ttf",
            bold: "Sarabun-Medium.ttf",
            italics: "Sarabun-Italic.ttf",
            bolditalics: "Sarabun-MediumItalic.ttf",
          },
        };
        const docDefinition = {
          pageMargins: [10, 70, 10, 100],
          pageSize: "A4",
          pageOrientation: "landscape",
          info: {
            title: "รายงานผลการตรวจสอบทรัพย์สินเเยกตามกลุ่มงาน",
          },
          header: [
            {
              text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
              alignment: "center",
              margin: [0, 15, 0, 0],
              style: "header",
            },
            {
              text: `รายงานผลการตรวจสอบทรัพย์สิน ประจำปีงบประมาณ ${this.$route.params.id.budget_year}`,
              alignment: "center",
              style: "header",
            },
            {
              text: `การรับ-จ่าย ทรัพย์สิน ตั้งเเต่วันที่ ${this.$route.params.id.start_date_th} - ${this.$route.params.id.stop_date_th}`,
              style: "header",
            },
  
            {
              text: `กลุ่มงาน ${this.$route.params.id.work_gid} - ${this.$route.params.id.work_gname}`,
              style: "header",
            },
          ],
          footer: {
            columns: [
              {
                text: `(ลงชื่อ)...................................................................(ประธานกรรมการ)
                (${this.$route.params.id?.director1 === null
                    ? "..................................................................."
                    : this.$route.params.id?.director1
                  })
                ตำแหน่ง ${this.$route.params.id?.director_positon1 === null
                    ? "..................................................................."
                    : this.$route.params.id?.director_positon1
                  } 
  
  
                  (ลงชื่อ)...................................................................(กรรมการ)
                (${this.$route.params.id?.director4 === null
                    ? "..................................................................."
                    : this.$route.params.id?.director4
                  })
                ตำแหน่ง ${this.$route.params.id?.director_positon4 === null
                    ? "..................................................................."
                    : this.$route.params.id?.director_positon4
                  }
                `,
                margin: [0, 20, 0, 10],
                alignment: "center",
                style: "text",
              },
              {
                text: `(ลงชื่อ)...................................................................(กรรมการ)
                (${this.$route.params.id?.director2 === null
                    ? "..................................................................."
                    : this.$route.params.id?.director2
                  })
                ตำแหน่ง ${this.$route.params.id?.director_positon2 === null
                    ? "..................................................................."
                    : this.$route.params.id?.director_positon2
                  } 
  
                  
                  (ลงชื่อ)...................................................................(กรรมการเเละเลขานุการ)
                (${this.$route.params.id?.director5 === null
                    ? "..................................................................."
                    : this.$route.params.id?.director5
                  })
                ตำแหน่ง ${this.$route.params.id?.director_positon5 === null
                    ? "..................................................................."
                    : this.$route.params.id?.director_positon5
                  }`,
                margin: [0, 20, 0, 10],
                alignment: "center",
                style: "text",
              },
              {
                text: `(ลงชื่อ)...................................................................(กรรมการ)
                (${this.$route.params.id?.director3 === null
                    ? "..................................................................."
                    : this.$route.params.id?.director3
                  })
                ตำแหน่ง ${this.$route.params.id?.director_positon3 === null
                    ? "..................................................................."
                    : this.$route.params.id?.director_positon3
                  }`,
                margin: [0, 20, 0, 10],
                alignment: "center",
                style: "text",
              },
  
            ],
            // columns: [
            //   {
            //     text: `(ลงชื่อ)...................................................................(กรรมการ)
            //     (${this.$route.params.id?.director4 === null
            //         ? "..................................................................."
            //         : this.$route.params.id?.director4
            //       })
            //     ตำแหน่ง ${this.$route.params.id?.director_positon4 === null
            //         ? "..................................................................."
            //         : this.$route.params.id?.director_positon4
            //       }`,
            //     margin: [0, 20, 0, 0],
            //     alignment: "center",
            //     style: "text",
            //   },
            //   {
            //     text: `(ลงชื่อ)...................................................................(กรรมการเเละเลขานุการ)
            //     (${this.$route.params.id?.director5 === null
            //         ? "..................................................................."
            //         : this.$route.params.id?.director5
            //       })
            //     ตำแหน่ง ${this.$route.params.id?.director_positon5 === null
            //         ? "..................................................................."
            //         : this.$route.params.id?.director_positon5
            //       }`,
            //     margin: [0, 20, 0, 0],
            //     alignment: "center",
            //     style: "text",
            //   },
            // ],
          },
  
  
          content: this.contentData,
          defaultStyle: {
            font: "Kanit",
          },
          styles: {
            tableExample: {
              margin: [0, 1, 0, 1],
            },
            tableHeader: {
              bold: true,
              fontSize: 5,
              color: "black",
              alignment: "center",
            },
            header: {
              fontSize: 9,
              bold: true,
              alignment: "center",
            },
            title: {
              bold: true,
              fontSize: 9,
              color: "black",
              margin: [0, 0, 0, 0],
            },
            anotherStyle: {
              italics: true,
              alignment: "center",
            },
            text_center: {
              alignment: "center",
              bold: false,
              fontSize: 5,
            },
            text_left: {
              alignment: "left",
              bold: false,
              fontSize: 5,
            },
            text_right: {
              bold: false,
              alignment: "right",
              fontSize: 5,
            },
            text: {
              fontSize: 7,
            },
          },
        };
        this.load_pdf = false;
        pdfMake
          .createPdf(docDefinition)
          .download(`รายงานผลการตรวจสอบวัสดุเเยกตามกลุ่มงาน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`);
        //pdfMake.createPdf(docDefinition).open();
      },
      async a_position(value) {
        // console.log(value);
        const { access_token } = await this.access_token();
        const url = `${API}registerWithdraw?fullname=${value.firstname}`;
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        // console.log(res.data.message.data);
        this.inspector_pos1 = res.data.message.data[0].dep_id;
      },
      async b_position(value) {
        // console.log(value);
        const { access_token } = await this.access_token();
        const url = `${API}registerWithdraw?fullname=${value.firstname}`;
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        // console.log(res.data.message.data);
        this.inspector_pos2 = res.data.message.data[0].dep_id;
      },
      async c_position(value) {
        // console.log(value);
        const { access_token } = await this.access_token();
        const url = `${API}registerWithdraw?fullname=${value.firstname}`;
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        // console.log(res.data.message.data);
        this.inspector_pos3 = res.data.message.data[0].dep_id;
      },
      async inspectorSignName() {
        const { access_token } = await this.access_token();
        const url = `${API}inspectorSignName/${this.$route.params.id.check_code}`;
        const head = {
          headers: {
            authorization: await access_token,
          },
        };
        const data = {
          inspector1: this.inspector1.firstname,
          inspector_pos1: this.inspector_pos1.dep_id || this.inspector_pos1,
          inspector2: this.inspector2.firstname,
          inspector_pos2: this.inspector_pos2.dep_id || this.inspector_pos2,
          inspector3: this.inspector3.firstname,
          inspector_pos3: this.inspector_pos3.dep_id || this.inspector_pos3,
        };
        const res = await axios.put(url, data, head);
        // console.log(res.data.message);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'บันทึก',
          showConfirmButton: false,
          timer: 1500,
        });
      },
      formatFn: function (value) {
        return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
      },
      async departmentLists() {
        const { access_token } = await this.access_token();
        const url = `${API}departmentLists`;
        const head = {
          headers: {
            authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        this.department_Lists = res.data.message.data;
        // console.log(res.data.message.data);
      },
      async get_data_table_user() {
        const { access_token } = await this.access_token();
        const url = `${API}user?_page=1&_limit=100`;
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        const res_user = res.data.message.data.map((rs) => {
          return {
            ...rs,
            firstname: `${rs.title}${rs.firstname} ${rs.lastname}`,
          };
        });
        this.alluser = res_user;
      },
      async exportExcel() {
        const { access_token } = await this.access_token();
        const url = `${API}readEquipmentCheckList?check_code=${this.$route.params.id.check_code}&_page=${this.currentPage}&_limit=${this.pageLength}&_sort=-1`;
  
        // console.log(url)
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        console.log(res.data.message.data);
        const res1 = res.data.message.data
        const res2 = []
        const url2 = await res1.map((res) => {
          // console.log(res)
          return `${API}propertyRegistration?e_number=${res.e_number}&lifetime=${res.lifetime}&refkey=${res.refkey}`;
        });
        for (let i in url2) {
          const data2 = await axios.get(url2[i], head);
          console.log(data2);
  
          res2.push(data2.data.message.data);
        }
        console.log(this.$route.params.id)
  
        var dateSelect = "";
        console.log("3333");
        var _checkdate = dayjs(this.$route.params.id.start_date).isBetween(
          this.$route.params.id.start_date.split("-")[0] + "-10-01",
          this.$route.params.id.start_date.split("-")[0] + "-12-31",
          undefined,
          "[]"
        );
        console.log(_checkdate);
        const chk = dayjs(this.$route.params.id.stop_date).isBetween(
          this.$route.params.id.stop_date.split("-")[0] + "-10-01",
          this.$route.params.id.stop_date.split("-")[0] + "-12-31",
          undefined,
          "[]"
        );
        console.log(chk);
        if (_checkdate == true && chk == true) {
          dateSelect = `${Number(this.$route.params.id.stop_date.split("-")[0]) + 1}`;
        } else if (_checkdate == true && chk == false) {
          dateSelect = `${Number(this.$route.params.id.stop_date.split("-")[0])}`;
        } else {
          dateSelect = `${Number(this.$route.params.id.stop_date.split("-")[0])}`;
        }
        // console.log(dateSelect)
        const ff = []
        let g_names = "";
        let lastData2 = []
        let lastData = []
        const dataFilter = res2.map((res, index) => {
          //  const [response] = res.filter((el, index2) => dateSelect.toString() == el.admitdate.substring(0, 4).toString() && index2 !== 0);
          res.forEach((element, index2) => {
            const cc = element.admitdate.substring(0, 4);
            //  console.log(cc)
            //  console.log(dateSelect.toString())
            if (cc == dateSelect.toString() && index2 !== 0) {
              ff.push(element);
            }
          });
          // console.log(response)
          // return response;
        });
        console.log(res1[0])
        ff.map(async (res, index) => {
          console.log(res)
          res1[index]["ค่าเสื่อมราคาประจำปี"] = ''
          //   console.log( res["ค่าเสื่อมราคาประจำปี"])
  
          // const cc= {
          //   'ค่าเสื่อมราคาประจำปี':await res["ค่าเสื่อมราคาประจำปี"]
          // }
  
          res1[index]["ค่าเสื่อมราคาสะสม"] = res["ค่าเสื่อมราคาสะสม"],
            res1[index]["มูลค่าสุทธิ"] = res["มูลค่าสุทธิ"],
            res1[index]["อัตราค่าเสื่อมราคาต่อปี"] = res["อัตราค่าเสื่อมราคาต่อปี"],
            res1[index]["อัตราค่าเสื่อมราคาต่อวัน"] = res["อัตราค่าเสื่อมราคาต่อวัน"]
          res1[index]["ค่าเสื่อมราคาประจำปี"] = res["ค่าเสื่อมราคาประจำปี"]
          console.log(res1)
          // console.log(res1[0].check_code)
  
          await (res1[index]["admitdate"] = `${parseInt(res.admitdate.split("-")[2])} ${this.month[parseInt(res.admitdate.split("-")[1])]
            } ${Number(res.admitdate.split("-")[0]) + 543}`)
  
          // await (res1[index]["ค่าเสื่อมราคาประจำปี"] = ),
          //  res1[index](cc)
          // await (res1[index]["ค่าเสื่อมราคาสะสม"] = res["ค่าเสื่อมราคาสะสม"]),
          // await (res1[index]["มูลค่าสุทธิ"] = res["มูลค่าสุทธิ"]),
          // await (res1[index]["อัตราค่าเสื่อมราคาต่อปี"] = res["อัตราค่าเสื่อมราคาต่อปี"]),
          // await (res1[index]["อัตราค่าเสื่อมราคาต่อวัน"] = res["อัตราค่าเสื่อมราคาต่อวัน"])
  
        });
  
  
        // console.log(res.data.message.data)
        // console.log(ff)
        console.log(res1)
  
        res1.map((res, index) => {
  
          //console.log(res.g_name, index);
          //console.log("dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd");
          const raw = {
            g_name: "",
            e_gid: "",
            ค่าเสื่อมประจำปี: "",
            ค่าเสื่อมราคาสะสม: "",
            จำนวน: "",
            จำหน่าย: "",
            ซ่อม: "",
            มูลค่ารวม: "",
            มูลค่าสุทธิ: "",
            ราคาต่อหน่วย: "",
            ราคาต่อหน่วยรวมvat: "",
            รายละเอียด: "",
            วันที่รับเข้า: "",
            สถานที่เก่า: "",
            สถานที่ใหม่: "",
            หน่วยนับ: "",
            หมายเลขทรัพย์สิน: "",
            อัตราค่าเสื่อมราคา: "",
            อัตราค่าเสื่อมราคาต่อปี: "",
            อัตราค่าเสื่อมราคาต่อวัน: "",
            อายุการใช้งาน: "",
            เลขทะเบียน: "",
            ใช้ได้ใช้ไม่ได้: "",
          };
          if (res.g_name === "" || res.g_name !== g_names) {
            // header
            raw["ชื่อทรัพย์สิน"] = res.g_name;
            //  lastData.push(raw);
            g_names = res.g_name;
            lastData2.push({ [res.g_name]: [] });
            //console.log(lastData2);
          }
          res["มูลค่ารวม"] = res["มูลค่ารวม"];
  
          res.keys = index;
          (res["ซ่อม"] = res["ซ่อม"] != "" && res["ซ่อม"] != undefined ? "ซ่อม" : ""),
            (res["จำหน่าย"] = res["จำหน่าย2"] != "" && res["จำหน่าย2"] != undefined ? "จำหน่าย" : "")
          console.log(res.ค่าเสื่อมราคาประจำปี)
  
  
          res["วันที่รับเข้า"] = res.admitdate
          res["เลขทะเบียน"] = res.equipment_code
          res["หมายเลขทรัพย์สิน"] = res.e_number
          res["ชื่อทรัพย์สิน"] = res.information
          res["รายละเอียด"] = res.e_name
          res["หน่วยนับ"] = res.unittype
          res["ราคาต่อหน่วย"] = res.unitprice
          res["จำนวน"] = res.amount
          res["มูลค่ารวม"] = res.unitprice
          res["อายุการใช้งาน"] = res.lifetime
          res["อัตราค่าเสื่อมราคา"] = res.อัตราค่าเสื่อมราคาต่อปี
          res["อัตราค่าเสื่อมราคาต่อปี"] = res.อัตราค่าเสื่อมราคาต่อปี
          res["ค่าเสื่อมราคาสะสม"] = res.ค่าเสื่อมราคาสะสม
          res["มูลค่าสุทธิ"] = res.มูลค่าสุทธิ
          res["ใช้ได้ใช้ไม่ได้"] = res.performance
          res["ซ่อม"] = res.ซ่อม
          res["จำหน่าย"] = res.จำหน่าย
          res["สถานที่เก่า"] = res.place
          res["สถานที่ใหม่"] = res.remark
  
  
          lastData.push(res);
  
  
  
          console.log(lastData);
          // console.log(lastData2);
          // console.log(res);
          for (let i in lastData2) {
            //console.log(Object.keys(lastData2[i]));
            //console.log(res);
  
            //console.log(lastData2[i][Object.keys(lastData2[i])]);
            if (res.g_name == Object.keys(lastData2[i])) {
              // console.log(lastData2);
              lastData2[i][Object.keys(lastData2[i])].push(res);
            }
          }
        });
  
        console.log(lastData)
  
  
        console.log(lastData2);
  
  
        var x1 = 0;
        var x2 = 0;
        var x3 = 0;
        var x4 = 0;
  
        for (let i in lastData2) {
          console.log(lastData2[i][Object.keys(lastData2[i])]);
          for (let r in lastData2[i][Object.keys(lastData2[i])]) {
            // console.log(lastData2[i][Object.keys(lastData2[i])][r].ค่าเสื่อมประจำปี)
            x1 += Number(lastData2[i][Object.keys(lastData2[i])][r].ค่าเสื่อมประจำปี);
            x2 += Number(lastData2[i][Object.keys(lastData2[i])][r].ค่าเสื่อมราคาสะสม);
            x3 += Number(lastData2[i][Object.keys(lastData2[i])][r].มูลค่าสุทธิ);
            x4 += Number(lastData2[i][Object.keys(lastData2[i])][r].ราคาต่อหน่วย);
          }
  
          console.log(lastData2)
          lastData2[i][Object.keys(lastData2[i])].push({
            ค่าเสื่อมประจำปี: x1,
            ค่าเสื่อมราคาสะสม: x2,
            มูลค่าสุทธิ: x3,
            มูลค่ารวม: x4,
            รายละเอียด: "รวมเป็นเงินทั้งสิ้น",
          });
          // lastData2[i][Object.keys(lastData2[i])].push(res);
          x1 = 0;
          x2 = 0;
          x3 = 0;
          x4 = 0;
        }
  
        // console.log(xxx);
        console.log(lastData2);
        console.log(lastData);
        let ll_data = lastData.map((element) => {
          console.log(element)
          return {
            ...element,
            'ใช้ได้': element.ใช้ได้ใช้ไม่ได้ == "ใช้ได้" ? "ใช้ได้" : '',
            'ใช้ไม่ได้': element.ใช้ได้ใช้ไม่ได้ == "สูญหาย" || element.ใช้ได้ใช้ไม่ได้ == "ใช้ไม่ได้" ? "สูญหาย" : '',
          }
        })
        for (let i in ll_data) {
          console.log(ll_data[i])
          // console.log(ll_data[i].ค่าเสื่อมราคาประจำปี)
          x1 += Number(ll_data[i].ค่าเสื่อมราคาประจำปี);
          x2 += Number(ll_data[i].ค่าเสื่อมราคาสะสม);
          x3 += Number(ll_data[i].มูลค่าสุทธิ);
          x4 += Number(ll_data[i].ราคาต่อหน่วย);
          console.log(i)
          console.log(x1)
  
  
          // x1 = 0;
          // x2 = 0;
          // x3 = 0;
          // x4 = 0;
        }
  
        ll_data.push({
          ค่าเสื่อมราคาประจำปี: x1,
          ค่าเสื่อมราคาสะสม: x2,
          มูลค่าสุทธิ: x3,
          มูลค่ารวม: x4,
          รายละเอียด: "รวมเป็นเงินทั้งสิ้น",
        });
        console.log(ll_data)
        saveExcel({
          data: ll_data,
          fileName: 'แบบสำรวจทรัพย์สินประจำปี',
          columns: [
            {
              title: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
              headerCellOptions: { textAlign: "center" },
              children: [
                {
                  title: ` รายงานผลการตรวจสอบทรัพย์สิน ประจำปีงบประมาณ ${this.$route.params.id.budget_year}`,
                  headerCellOptions: { textAlign: "center" },
                  children: [
                    {
                      title: `การรับ-จ่าย ทรัพย์สิน ตั้งเเต่วันที่ ${this.$route.params.id.start_date_th} - ${this.$route.params.id.stop_date_th}`,
                      headerCellOptions: { textAlign: "center" },
                      children: [
                        {
                          title: ` กลุ่มงาน ${this.$route.params.id.work_gid} - ${this.$route.params.id.work_gname}`,
  
                          headerCellOptions: { textAlign: "center" },
                          children: [
                            {
                              title: "วันที่-เดือน-ปี",
                              field: "วันที่รับเข้า",
                            },
                            {
                              title: "เลขทะเบียน",
                              field: "เลขทะเบียน",
                            },
                            {
                              title: "รหัสทรัพย์สิน",
                              field: "หมายเลขทรัพย์สิน",
                            },
  
                            {
                              title: "รายการ",
                              field: "รายละเอียด",
                            },
  
                            {
                              title: "หน่วยนับ",
                              field: "หน่วยนับ",
                            },
                            {
                              title: "ราคาต่อหน่วย",
                              field: "ราคาต่อหน่วย",
                            },
                            {
                              title: "จำนวน",
                              field: "จำนวน",
                            },
                            {
                              title: "มูลค่ารวม",
                              field: "มูลค่ารวม",
                            },
                            {
                              title: "อายุการใช้งาน",
                              field: "อายุการใช้งาน",
                            },
                            {
                              title: "อัตราค่าเสื่อมราคา",
                              field: "อัตราค่าเสื่อมราคา",
                            },
                            {
                              title: "ค่าเสื่อมประจำปี",
                              field: "ค่าเสื่อมราคาประจำปี",
                            },
                            {
                              title: "ค่าเสื่อมราคาสะสม",
                              field: "ค่าเสื่อมราคาสะสม",
                            },
                            {
                              title: "มูลค่าสุทธิ",
                              field: "มูลค่าสุทธิ",
                            },
                            {
                              title: "สภาพ",
                              headerCellOptions: { textAlign: "center" },
                              children: [
                                {
                                  title: "ใช้ได้",
                                  field: "ใช้ได้",
                                },
                                {
                                  title: "ใช้ไม่ได้",
                                  field: "ใช้ไม่ได้",
                                },
                                {
                                  title: "ซ่อม",
                                  field: "ซ่อม",
                                },
                                {
                                  title: "จำหน่าย",
                                  field: "จำหน่าย",
                                },
                              ],
                            },
                            {
                              title: "สถานที่/เจ้าหน้าที่(เดิม)",
                              field: "สถานที่เก่า",
                            },
                            {
                              title: "สถานที่/เจ้าหน้าที่(ใหม่)",
                              field: "สถานที่ใหม่",
                            },
                          ],
                        },
                        {},
                      ],
                    },
                    {},
                  ],
                },
                {},
              ],
            },
          ],
        });
      },
      handlePagechange(event) {
        this.currentPage = event;
        this.get_data_table();
      },
      handleChangePage(event) {
        this.pageLength = event;
        this.get_data_table();
      },
      async get_data_table() {
        this.show = true;
        const { access_token } = await this.access_token();
        const url = `${API}readEquipmentCheckList?check_code=${this.$route.params.id.check_code}&_page=${this.currentPage}&_limit=${this.pageLength}&_sort=-1`;
  
        // console.log(url)
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        console.log(res.data.message.data);
        const res1 = res.data.message.data
        const res2 = []
        const url2 = await res1.map((res) => {
          // console.log(res)
          return `${API}propertyRegistration?e_number=${res.e_number}&lifetime=${res.lifetime}&refkey=${res.refkey}`;
        });
        for (let i in url2) {
          const data2 = await axios.get(url2[i], head);
          console.log(data2);
  
          res2.push(data2.data.message.data);
        }
        console.log(this.$route.params.id)
  
        var dateSelect = "";
        console.log("3333");
        var _checkdate = dayjs(this.$route.params.id.start_date).isBetween(
          this.$route.params.id.start_date.split("-")[0] + "-10-01",
          this.$route.params.id.start_date.split("-")[0] + "-12-31",
          undefined,
          "[]"
        );
        console.log(_checkdate);
        const chk = dayjs(this.$route.params.id.stop_date).isBetween(
          this.$route.params.id.stop_date.split("-")[0] + "-10-01",
          this.$route.params.id.stop_date.split("-")[0] + "-12-31",
          undefined,
          "[]"
        );
        console.log(chk);
        if (_checkdate == true && chk == true) {
          dateSelect = `${Number(this.$route.params.id.stop_date.split("-")[0]) + 1}`;
        } else if (_checkdate == true && chk == false) {
          dateSelect = `${Number(this.$route.params.id.stop_date.split("-")[0])}`;
        } else {
          dateSelect = `${Number(this.$route.params.id.stop_date.split("-")[0])}`;
        }
        // console.log(dateSelect)
        const ff = []
        let g_names = "";
        let lastData2 = []
        let lastData = []
        const dataFilter = res2.map((res, index) => {
          //  const [response] = res.filter((el, index2) => dateSelect.toString() == el.admitdate.substring(0, 4).toString() && index2 !== 0);
          res.forEach((element, index2) => {
            const cc = element.admitdate.substring(0, 4);
            //  console.log(cc)
            //  console.log(dateSelect.toString())
            if (cc == dateSelect.toString() && index2 !== 0) {
              ff.push(element);
            }
          });
          // console.log(response)
          // return response;
        });
        console.log(res1[0])
        ff.map(async (res, index) => {
          console.log(res)
          res1[index]["ค่าเสื่อมราคาประจำปี"] = ''
          //   console.log( res["ค่าเสื่อมราคาประจำปี"])
  
          // const cc= {
          //   'ค่าเสื่อมราคาประจำปี':await res["ค่าเสื่อมราคาประจำปี"]
          // }
  
          res1[index]["ค่าเสื่อมราคาสะสม"] = res["ค่าเสื่อมราคาสะสม"],
            res1[index]["มูลค่าสุทธิ"] = res["มูลค่าสุทธิ"],
            res1[index]["อัตราค่าเสื่อมราคาต่อปี"] = res["อัตราค่าเสื่อมราคาต่อปี"],
            res1[index]["อัตราค่าเสื่อมราคาต่อวัน"] = res["อัตราค่าเสื่อมราคาต่อวัน"]
          res1[index]["ค่าเสื่อมราคาประจำปี"] = res["ค่าเสื่อมราคาประจำปี"]
          console.log(res1)
          // console.log(res1[0].check_code)
  
          await (res1[index]["admitdate"] = `${parseInt(res.admitdate.split("-")[2])} ${this.month[parseInt(res.admitdate.split("-")[1])]
            } ${Number(res.admitdate.split("-")[0]) + 543}`)
  
          // await (res1[index]["ค่าเสื่อมราคาประจำปี"] = ),
          //  res1[index](cc)
          // await (res1[index]["ค่าเสื่อมราคาสะสม"] = res["ค่าเสื่อมราคาสะสม"]),
          // await (res1[index]["มูลค่าสุทธิ"] = res["มูลค่าสุทธิ"]),
          // await (res1[index]["อัตราค่าเสื่อมราคาต่อปี"] = res["อัตราค่าเสื่อมราคาต่อปี"]),
          // await (res1[index]["อัตราค่าเสื่อมราคาต่อวัน"] = res["อัตราค่าเสื่อมราคาต่อวัน"])
  
        });
  
  
        // console.log(res.data.message.data)
        // console.log(ff)
        console.log(res1)
  
        res1.map((res, index) => {
  
          //console.log(res.g_name, index);
          //console.log("dddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd");
          const raw = {
            g_name: "",
            e_gid: "",
            ค่าเสื่อมประจำปี: "",
            ค่าเสื่อมราคาสะสม: "",
            จำนวน: "",
            จำหน่าย: "",
            ซ่อม: "",
            มูลค่ารวม: "",
            มูลค่าสุทธิ: "",
            ราคาต่อหน่วย: "",
            ราคาต่อหน่วยรวมvat: "",
            รายละเอียด: "",
            วันที่รับเข้า: "",
            สถานที่เก่า: "",
            สถานที่ใหม่: "",
            หน่วยนับ: "",
            หมายเลขทรัพย์สิน: "",
            อัตราค่าเสื่อมราคา: "",
            อัตราค่าเสื่อมราคาต่อปี: "",
            อัตราค่าเสื่อมราคาต่อวัน: "",
            อายุการใช้งาน: "",
            เลขทะเบียน: "",
            ใช้ได้ใช้ไม่ได้: "",
          };
          if (res.g_name === "" || res.g_name !== g_names) {
            // header
            raw["ชื่อทรัพย์สิน"] = res.g_name;
            lastData.push(raw);
            g_names = res.g_name;
            lastData2.push({ [res.g_name]: [] });
            //console.log(lastData2);
          }
          res["มูลค่ารวม"] = res["มูลค่ารวม"];
  
          res.keys = index;
          (res["ซ่อม"] = res["ซ่อม"] != "" && res["ซ่อม"] != undefined ? "ซ่อม" : ""),
            (res["จำหน่าย"] = res["จำหน่าย2"] != "" && res["จำหน่าย2"] != undefined ? "จำหน่าย" : "")
          console.log(res.ค่าเสื่อมราคาประจำปี)
  
  
          res["วันที่รับเข้า"] = res.admitdate
          res["เลขทะเบียน"] = res.equipment_code
          res["หมายเลขทรัพย์สิน"] = res.e_number
          res["ชื่อทรัพย์สิน"] = res.information
          res["รายละเอียด"] = res.e_name
          res["หน่วยนับ"] = res.unittype
          res["ราคาต่อหน่วย"] = res.unitprice
          res["จำนวน"] = res.amount
          res["มูลค่ารวม"] = res.unitprice
          res["อายุการใช้งาน"] = res.lifetime
          res["อัตราค่าเสื่อมราคา"] = res.อัตราค่าเสื่อมราคาต่อปี
          res["อัตราค่าเสื่อมราคาต่อปี"] = res.ค่าเสื่อมราคาประจำปี
          res["ค่าเสื่อมราคาสะสม"] = res.ค่าเสื่อมราคาสะสม
          res["มูลค่าสุทธิ"] = res.มูลค่าสุทธิ
          res["ใช้ได้ใช้ไม่ได้"] = res.performance
          res["ซ่อม"] = res.ซ่อม
          res["จำหน่าย"] = res.จำหน่าย
          res["สถานที่เก่า"] = res.place
          res["สถานที่ใหม่"] = res.remark
  
  
          lastData.push(res);
          console.log(lastData);
          // console.log(lastData2);
          // console.log(res);
          for (let i in lastData2) {
            //console.log(Object.keys(lastData2[i]));
            //console.log(res);
  
            //console.log(lastData2[i][Object.keys(lastData2[i])]);
            if (res.g_name == Object.keys(lastData2[i])) {
              // console.log(lastData2);
              lastData2[i][Object.keys(lastData2[i])].push(res);
            }
          }
        });
  
        console.log(lastData)
  
  
        this.rows = lastData.map((el) => {
          console.log(el)
          // const vv = {
          //   'อัตราค่าเสื่อมราคาต่อปี': el.อัตราค่าเสื่อมราคาต่อปี
          // }
  
          return {
            ...el,
            //ชื่อทรัพย์สิน: el.information,
            ใช้ได้: el.ใช้ได้ใช้ไม่ได้ == 'ใช้ได้' ? 'ใช้ได้' : '',
            ใช้ไม่ได้: el.ใช้ได้ใช้ไม่ได้ == "สูญหาย" || el.ใช้ได้ใช้ไม่ได้ == "ใช้ไม่ได้" ? "สูญหาย" : '',
            unitprice_ex: Number(el.unitprice),
  
          };
        }),
          this.total = res.data.message.total;
        this.show = false;
      },
      async access_token() {
        return (
          await axios.post(`${API}permit`, '', {
            headers: {
              Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
            },
          })
        ).data.message;
      },
  
      getfrom() {
        // console.log(id);
      },
    },
  };
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  